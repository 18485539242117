import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import FormDatePicker, {
  DATE_PATTERN,
} from "../../../../../../components/molecules/form-date-picker";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import { fieldVisibility } from "../../../../../../utils";
import FormDropdown from "../../../../../../components/molecules/form-dropdown";
import FormTextInput from "../../../../../../components/molecules/form-text-input";
import FormTimePicker from "../../../../../../components/molecules/form-time-picker";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";
import {
  ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS,
  HOROSCOPE_MATCHING_OPTIONS,
} from "../../../../../../static-data";

const HoroscopeInformation = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {
    values,
    errors,
    initialValues,
    submitCount,
    handleChange,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const { countries, starOptions, starSignOptions, haveDoshOptions } =
    useSelector((state) => state.genericData);
  const [birthTime, setBirthTime] = useState(
    values[FormMeta.birthTime.fieldName]
  );

  const dobStartDate = moment().subtract(60, "years").format(DATE_PATTERN);
  const dobEndDate = moment().subtract(18, "years").format(DATE_PATTERN);
  const showFieldErrors = submitCount > 0;

  useEffect(() => {
    setBirthTime(values[FormMeta.birthTime.fieldName]);
  }, [values]);

  return (
    <Grid
      container
      direction="row"
      className="row-gap-8"
      style={{ marginTop: 16 }}
    >
      <Grid item className="form-field-container">
        <FormDropdown
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.horoscopeMatching,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(
                FormMeta.horoscopeMatching,
                values,
                t,
                setFieldError
              )
            );
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(
                FormMeta.horoscopeMatching,
                initialValues,
                setFieldValue
              )
            );
          }}
          fieldName={FormMeta.horoscopeMatching.fieldName}
          value={values[FormMeta.horoscopeMatching.fieldName]}
          onChange={(e) => {
            setFieldValue(
              FormMeta.horoscopeMatching.fieldName,
              JSON.parse(
                e.target.value ??
                  FormMeta.horoscopeMatching.defaultValue.toString()
              )
            );
          }}
          label={t(FormMeta.horoscopeMatching.label)}
          lang={i18n.language}
          options={HOROSCOPE_MATCHING_OPTIONS}
          errorText={showFieldErrors && errors.horoscopeMatching}
          visibility={fieldVisibility(FormMeta.horoscopeMatching)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormDropdown
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.acceptOtherCaste,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(FormMeta.acceptOtherCaste, values, t, setFieldError)
            );
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(
                FormMeta.acceptOtherCaste,
                initialValues,
                setFieldValue
              )
            );
          }}
          fieldName={FormMeta.acceptOtherCaste.fieldName}
          value={values[FormMeta.acceptOtherCaste.fieldName]}
          onChange={handleChange}
          options={ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS}
          keyExtractor={FormMeta.acceptOtherCaste.keyExtractor}
          labelExtractor={FormMeta.acceptOtherCaste.labelExtractor}
          label={t(FormMeta.acceptOtherCaste.label)}
          lang={i18n.language}
          errorText={errors.acceptOtherCaste}
          visibility={fieldVisibility(FormMeta.acceptOtherCaste)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormDatePicker
          value={values[FormMeta.dob.fieldName]}
          startDate={dobStartDate}
          endDate={dobEndDate}
          label={t(FormMeta.dob.label)}
          errorText={showFieldErrors && errors[FormMeta.dob.fieldName]}
          visibility={fieldVisibility(FormMeta.dob)}
          disabled={true}
          lang={i18n.language}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormDropdown
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.originCountryCode,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(
                FormMeta.originCountryCode,
                values,
                t,
                setFieldError
              )
            );
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(
                FormMeta.originCountryCode,
                initialValues,
                setFieldValue
              )
            );
          }}
          fieldName={FormMeta.originCountryCode.fieldName}
          value={values[FormMeta.originCountryCode.fieldName]}
          label={t(FormMeta.originCountryCode.label)}
          onChange={handleChange}
          lang={i18n.language}
          options={countries}
          keyExtractor={FormMeta.originCountryCode.keyExtractor}
          labelExtractor={FormMeta.originCountryCode.labelExtractor}
          errorText={
            showFieldErrors && errors[FormMeta.originCountryCode.fieldName]
          }
          visibility={fieldVisibility(FormMeta.originCountryCode)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormTimePicker
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.birthTime,
            initialValues,
            values: {
              ...values,
              [FormMeta.birthTime.fieldName]: birthTime,
            },
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(
                FormMeta.birthTime,
                {
                  ...values,
                  [FormMeta.birthTime.fieldName]: birthTime,
                },
                t,
                setFieldError
              )
            );
          }}
          defaultTime={initialValues[FormMeta.birthTime.fieldName]}
          value={birthTime}
          onChange={(value) => {
            setBirthTime(value);
          }}
          label={
            t(FormMeta.birthTime.label) + " (" + t("common.optional") + ")"
          }
          lang={i18n.language}
          visibility={fieldVisibility(FormMeta.birthTime)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormTextInput
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.birthCity,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(FormMeta.birthCity, values, t, setFieldError)
            );
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(
                FormMeta.birthCity,
                initialValues,
                setFieldValue
              )
            );
          }}
          fieldName={FormMeta.birthCity.fieldName}
          value={values[FormMeta.birthCity.fieldName]}
          onChange={handleChange}
          label={
            t(FormMeta.birthCity.label) + " (" + t("common.optional") + ")"
          }
          //   labelStyles={labelStyles}
          lang={i18n.language}
          errorText={showFieldErrors && errors[FormMeta.birthCity.fieldName]}
          visibility={fieldVisibility(FormMeta.birthCity)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormDropdown
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.starId,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(updateAdField(FormMeta.starId, values, t, setFieldError));
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(FormMeta.starId, initialValues, setFieldValue)
            );
          }}
          fieldName={FormMeta.starId.fieldName}
          value={values[FormMeta.starId.fieldName]}
          label={t(FormMeta.starId.label)}
          onChange={handleChange}
          lang={i18n.language}
          options={starOptions}
          keyExtractor={FormMeta.starId.keyExtractor}
          labelExtractor={FormMeta.starId.labelExtractor}
          errorText={showFieldErrors && errors[FormMeta.starId.fieldName]}
          visibility={fieldVisibility(FormMeta.starId)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormDropdown
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.starSignId,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(FormMeta.starSignId, values, t, setFieldError)
            );
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(
                FormMeta.starSignId,
                initialValues,
                setFieldValue
              )
            );
          }}
          fieldName={FormMeta.starSignId.fieldName}
          value={values[FormMeta.starSignId.fieldName]}
          label={t(FormMeta.starSignId.label)}
          onChange={handleChange}
          lang={i18n.language}
          options={starSignOptions}
          keyExtractor={FormMeta.starSignId.keyExtractor}
          labelExtractor={FormMeta.starSignId.labelExtractor}
          errorText={showFieldErrors && errors[FormMeta.starSignId.fieldName]}
          visibility={fieldVisibility(FormMeta.starSignId)}
        />
      </Grid>
      <Grid item className="form-field-container">
        <FormDropdown
          mode="interactive"
          showInteractiveActions={hasChangedFormValue({
            meta: FormMeta.haveDosh,
            initialValues,
            values,
          })}
          onClickInteractiveSave={() => {
            dispatch(
              updateAdField(FormMeta.haveDosh, values, t, setFieldError)
            );
          }}
          onClickInteractiveClear={() => {
            dispatch(
              resetChangedField(FormMeta.haveDosh, initialValues, setFieldValue)
            );
          }}
          fieldName={FormMeta.haveDosh.fieldName}
          value={values[FormMeta.haveDosh.fieldName]}
          label={t(FormMeta.haveDosh.label)}
          onChange={handleChange}
          lang={i18n.language}
          options={haveDoshOptions}
          keyExtractor={FormMeta.haveDosh.keyExtractor}
          labelExtractor={FormMeta.haveDosh.labelExtractor}
          errorText={showFieldErrors && errors[FormMeta.haveDosh.fieldName]}
          visibility={fieldVisibility(FormMeta.haveDosh)}
        />
      </Grid>
    </Grid>
  );
};

export default HoroscopeInformation;
