import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Payment from "../components/payment/Payment";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import DeprecationHandler from "../v2/utils/deprecation-handler";
import AppContext from "../v2/context";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Config from "../v2/config";
import { isEmpty } from "lodash";
import UserApi from "../v2/services/api/user";
import FlowStepper from "../v2/pages/create-flow/templates/stepper";
import InvoiceView from "../v2/pages/pay-for-ad/invoice-view";
import PaymentView from "../v2/templates/payment-view";
import withLanguageMigrator from "../v2/hoc/language-migrator";

// export default class PaymentForAd extends Component {
//   state = {
//     lng: true, // default en
//     isRenew: sessionStorage.getItem("isRenew")
//       ? sessionStorage.getItem("isRenew") === "true"
//         ? true
//         : false
//       : false,
//     isAccessDenied: false,
//   };

//   componentDidMount = () => {
//     this.setState({
//       lng: localStorage.getItem("lng")
//         ? localStorage.getItem("lng") === "true"
//           ? true
//           : false
//         : true,
//       isAccessDenied:
//         localStorage.getItem("postId") &&
//         localStorage.getItem("auth_token") &&
//         getPayemntStatus()
//           ? false
//           : true,
//     });

//     // get my post
//     let url = "";
//     if (
//       localStorage.getItem("user_role") === "OPERATOR" ||
//       localStorage.getItem("user_role") === "OPERATOR_L2"
//     ) {
//       url = `operator/managed_posts/${localStorage.getItem("postId")}`;
//     } else {
//       url = "account/posts";
//     }
//     axios
//       .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
//         },
//       })
//       .then((res) => {
//         if (
//           localStorage.getItem("user_role") === "OPERATOR" ||
//           localStorage.getItem("user_role") === "OPERATOR_L2"
//         ) {
//           res.data[0] = res.data;
//         }
//         const isRenew =
//           res.data[0].status === "PAYMENT_PENDING"
//             ? false
//             : res.data[0].status === "EXPIRED"
//             ? true
//             : res.data[0].status === "LIVE"
//             ? true
//             : false;
//         this.setState({
//           isRenew,
//         });

//         sessionStorage.setItem("isRenew", isRenew);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   handleLng = () => {
//     this.setState({ lng: !this.state.lng });

//     localStorage.setItem("lng", !this.state.lng);
//   };

//   render() {
//     return this.state.isAccessDenied ? (
//       <Unauthorize title="Payment Invoice | Poruwa.lk" />
//     ) : (
//       <React.Fragment>
//         <PageHead title="Payment Invoice | Poruwa.lk" />

//         <Navbar handleLng={this.handleLng} lng={this.state.lng} />
//         <div style={{ background: "#f7f7f7" }}>
//           <Grid
//             container
//             xs={12}
//             sm={10}
//             md={8}
//             direction="column"
//             className="cus-container complete-ad-container"
//           >
//             {this.state.isRenew ? (
//               <Typography
//                 variant="h3"
//                 align="center"
//                 className="create-new-ad"
//                 style={{
//                   paddingBottom: this.state.isRenew ? 30 : 0,
//                 }}
//               >
//                 {this.state.lng === true ? (
//                   "Renew Ad"
//                 ) : (
//                   <span>දැන්වීම අලුත් කරන්න</span>
//                 )}
//               </Typography>
//             ) : (
//               <React.Fragment>
//                 <Typography
//                   variant="h3"
//                   align="center"
//                   className="create-new-ad"
//                 >
//                   {this.state.lng === true ? (
//                     "New Ad"
//                   ) : (
//                     <span>නව දැන්වීමක් නිර්මාණය කිරීම</span>
//                   )}
//                 </Typography>
//                 <ProgressBar lng={this.state.lng} />
//               </React.Fragment>
//             )}

//             <Payment align="center" lng={this.state.lng} />
//           </Grid>
//         </div>
//         <div className="create-floor-footer-desktop">
//           <Footer lng={this.state.lng} />
//         </div>
//         <div className="create-floor-footer-mobile">
//           <FooterMobile lng={this.state.lng} />
//         </div>
//       </React.Fragment>
//     );
//   }
// }

//CodeFlow Enhancement
const PaymentForAd = ({ lng }) => {
  const { t } = useTranslation();
  const { authAccount, authProfile } = useSelector(
    (state) => state.application
  );
  const [advertisement, setAdvertisement] = useState({});
  const headingContainerRef = useRef(null);

  useEffect(() => {
    if (isEmpty(authAccount)) {
      return;
    }

    const operatorRoles = [
      Config.USER_ROLES.OPERATOR,
      Config.USER_ROLES.OPERATOR_L2,
    ];

    if (operatorRoles.includes(authAccount.role)) {
      const postId = localStorage.getItem("postId");
      if (postId !== null) {
        UserApi.getOperatorManagedProfile({ postId }).then((response) => {
          if (!response.success) {
            return; // TODO: Error Handling
          }
          setAdvertisement(response.body[0] ?? {});
        });
      }
    } else {
      setAdvertisement(authProfile);
    }
  }, [authAccount, authProfile]);

  const isRenewProfile = useMemo(() => {
    const renewalProfileStatus = ["EXPIRED", "LIVE"];
    const output =
      !isEmpty(advertisement) &&
      renewalProfileStatus.includes(advertisement.status);
    sessionStorage.setItem("isRenew", output);
    return output;
  }, [advertisement]);

  const eligibleToPurchase = useMemo(() => {
    if (isEmpty(advertisement)) {
      return false;
    }
    const paymentEligibleStatus = ["PAYMENT_PENDING", "LIVE", "EXPIRED"];
    return paymentEligibleStatus.includes(advertisement.status);
  }, [advertisement]);

  if (!DeprecationHandler.isAuthAuthorized()) {
    return <Unauthorize title="Payment Invoice | Poruwa.lk" />;
  }

  if (!eligibleToPurchase) {
    return (
      <PaymentView
        advertisementId={advertisement.memberId}
        advertisementStatus={advertisement.status}
      />
    );
  }

  let contentTopMargin = 50;
  if (headingContainerRef.current !== null) {
    const headingContainer = headingContainerRef.current;
    const boundingRectangle = headingContainer.getBoundingClientRect();
    contentTopMargin = Math.ceil(boundingRectangle.height);
  }

  return (
    <React.Fragment>
      <PageHead title={`Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <Grid
        container
        direction="column"
        style={{
          minHeight: "100vh",
          justifyContent: "space-between",
          backgroundColor: "#f7f7f7",
        }}
      >
        <Grid item>
          <Grid container direction="column" ref={headingContainerRef}>
            <Typography variant="h3" align="center" className="create-new-ad">
              {t(
                isRenewProfile
                  ? "createflow.renewAd"
                  : "createflow.create_a_new_ad"
              )}
            </Typography>
            {!isRenewProfile && <FlowStepper />}
          </Grid>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container complete-ad-container"
            style={{ marginTop: contentTopMargin }}
          >
            {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN ? (
              <InvoiceView advertisement={advertisement} />
            ) : (
              <Payment align="center" lng={lng} advertisement={advertisement} />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <div className="create-floor-footer-desktop">
            <Footer lng={lng} />
          </div>
          <div className="create-floor-footer-mobile">
            <FooterMobile lng={lng} />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withLanguageMigrator(PaymentForAd);
