import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";

// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Header from "../components/post-details/Header";
import Connect from "../components/post-details/Connect";
import Personal from "../components/post-details/Personal";
import Parent from "../components/post-details/Parent";
import Horoscope from "../components/post-details/Horoscope";
import RemovedAd from "../components/post-details/RemovedAd";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import Config from "../v2/config";

import "../styles/post-details.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class MyAdd extends Component {
  state = {
    isAccessDenied: false,
    applyMatch: false,
    applyNormalPost: false,
    isRemovedAd: false,

    profileType: "full",
    postData: {},
    // personal
    postId: "",
    publishedAt: "",
    gender: "",
    fName: "",
    lname: "",
    lnameMasked: "",
    residentCity: "",
    residentRegion: "",
    countryCode: "",
    createdBy: "",
    age: "",
    profession: "",
    profession_si: "",
    caste: "",
    height: "",
    drinking: "",
    smoking: "",
    description: "",
    differentlyAbled: "",
    nicVerified: "",
    isFeatured: false,
    displayName: "",
    children: "",

    // parent
    fCountryOfResidency: "",
    fCountryOfResidency_si: "",
    professionF: "",
    ethnicityF: "",
    religionF: "",
    casteF: "",
    additionalInfoF: "",
    mCountryOfResidency: "",
    mCountryOfResidency_si: "",
    professionM: "",
    ethnicityM: "",
    religionM: "",
    casteM: "",
    additionalInfoM: "",

    // private
    imageHoroData: [],
    imageData: [],
    images: [],
    phone: "",
    email: "",
    horoscopeMatching: false,
    horoscopeInfo: { images: ["1a408879db324950974c367be8e3988a.jpg"] },
    dob: "",

    // page loading
    preLoading: true,

    // alert modal
    alertModal: false,
    alertHeading: "",
    alertMessage: "",

    siblingsInfo: [],
  };

  componentDidMount = () => {
    this.setState({
      isAccessDenied:
        localStorage.getItem("auth_token") && localStorage.getItem("postId")
          ? false
          : true,
    });

    this.getPostInfo();

    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  getNonInterestedData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/posts/${localStorage.getItem(
          "postId"
        )}${
          localStorage.getItem("postId")
            ? localStorage.getItem("post_details")
              ? `?context=${localStorage.getItem("postId")}`
              : ""
            : ""
        }
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        const father = res.data.parentInfo.filter(
          (filter) => filter.type === "FATHER"
        );

        const mother = res.data.parentInfo.filter(
          (filter) => filter.type === "MOTHER"
        );

        this.setState({
          applyNormalPost: true,
          applyMatch: false,
          postData: res.data,
          postId: res.data.id,
          publishedAt: res.data.publishedAt,
          country:
            res.data.personalInfo.residentCountry.code === "ZZ"
              ? res.data.personalInfo.residentCountryOther
                ? res.data.personalInfo.residentCountryOther.name
                : ""
              : res.data.personalInfo.residentCountry.name,
          country_si:
            res.data.personalInfo.residentCountry.code === "ZZ"
              ? res.data.personalInfo.residentCountryOther
                ? res.data.personalInfo.residentCountryOther.name
                : ""
              : res.data.personalInfo.residentCountry.name_si,
          gender: res.data.personalInfo.gender,
          fName: res.data.personalInfo.fname,
          displayName: res.data.personalInfo.displayName,
          lnameMasked: res.data.personalInfo.lnameMasked,
          createdBy: res.data.createdBy,
          age: res.data.personalInfo.age,
          residentCity: res.data.personalInfo.residentCity,
          residentRegion: res.data.personalInfo.residentRegion
            ? res.data.personalInfo.residentRegion.regionName
            : "",
          residentRegion_si: res.data.personalInfo.residentRegion
            ? res.data.personalInfo.residentRegion.regionName_si
            : "",

          countryCode:
            res.data.personalInfo.residentCountry.code === "ZZ"
              ? res.data.personalInfo.residentCountryOther
                ? res.data.personalInfo.residentCountryOther.code
                : ""
              : res.data.personalInfo.residentCountry.code,

          description: res.data.personalInfo.additionalInfo,

          ethnicity: res.data.personalInfo.ethnicity.name,
          ethnicity_si: res.data.personalInfo.ethnicity.name_si,

          religion: res.data.personalInfo.religion.name,
          religion_si: res.data.personalInfo.religion.name_si,
          caste: res.data.personalInfo.caste,
          height: res.data.personalInfo.height,

          status: res.data.personalInfo.civilStatus.status,
          status_si: res.data.personalInfo.civilStatus.status_si,

          originCountry: res.data.personalInfo.originCountry.name,
          originCountry_si: res.data.personalInfo.originCountry.name_si,

          visaType: res.data.personalInfo.visaType.visaType,
          visaType_si: res.data.personalInfo.visaType.visaType_si,

          eduLevel: res.data.personalInfo.educationLevel.level,
          eduLevel_si: res.data.personalInfo.educationLevel.level_si,

          profession:
            res.data.personalInfo.profession.name === "Other"
              ? res.data.personalInfo.professionOther
              : res.data.personalInfo.profession.name,
          profession_si:
            res.data.personalInfo.profession.name === "Other"
              ? res.data.personalInfo.professionOther
              : res.data.personalInfo.profession.name_si,

          drinking: res.data.personalInfo.drinking,
          smoking: res.data.personalInfo.smoking,

          foodPref: res.data.personalInfo.foodPreference.type,
          foodPref_si: res.data.personalInfo.foodPreference.type_si,
          fCountryOfResidency: father[0].residentCountry
            ? father[0].residentCountry.name
            : "",
          fCountryOfResidency_si: father[0].residentCountry
            ? father[0].residentCountry.name_si
            : "",
          professionF: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name
            : "",
          professionF_si: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name_si
            : "",
          ethnicityF: father[0].ethnicity.name,
          ethnicityF_si: father[0].ethnicity.name_si,
          religionF: father[0].religion.name,
          religionF_si: father[0].religion.name_si,
          casteF: father[0].caste,
          additionalInfoF: father[0].additionalInfo,
          mCountryOfResidency: mother[0].residentCountry
            ? mother[0].residentCountry.name
            : "",
          mCountryOfResidency_si: mother[0].residentCountry
            ? mother[0].residentCountry.name_si
            : "",
          professionM: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name
            : "",
          professionM_si: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name_si
            : "",
          ethnicityM: mother[0].ethnicity.name,
          ethnicityM_si: mother[0].ethnicity.name_si,
          religionM: mother[0].religion.name,
          religionM_si: mother[0].religion.name_si,
          casteM: mother[0].caste,
          additionalInfoM: mother[0].additionalInfo,

          liked: res.data.liked ? res.data.liked : "",
          matched: res.data.matched ? res.data.matched : "",
          phone: res.data.phone,
          preLoading: false,
          children:
            res.data.personalInfo &&
            res.data.personalInfo.civilStatus &&
            res.data.personalInfo.civilStatus.status !== "Never Married" &&
            res.data.personalInfo.children
              ? res.data.personalInfo.children
              : "",
        });
      })
      .catch((err) => {
        console.log(err);

        this.setState({ preLoading: false, isRemovedAd: true });
      });
  };

  getPostInfo = () => {
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        const father = res.data[0].parentInfo.filter(
          (filter) => filter.type === "FATHER"
        );

        const mother = res.data[0].parentInfo.filter(
          (filter) => filter.type === "MOTHER"
        );

        this.setState({
          differentlyAbled: res.data[0].differentlyAbled ? "Yes" : "No",
          nicVerified: res.data[0].idVerified ? "Yes" : "No",
          isFeatured: res.data[0].isFeatured,
          imageHoroData: [],
          applyNormalPost: false,
          applyMatch: true,
          postData: res.data[0],
          postId: res.data[0].id,
          publishedAt: res.data[0].publishedAt,
          gender: res.data[0].personalInfo.gender,
          createdBy: res.data[0].createdBy,
          fName: res.data[0].personalInfo.fname,
          displayName: res.data[0].personalInfo.displayName,
          lnameMasked: res.data[0].personalInfo.lnameMasked,
          lname: res.data[0].personalInfo.lname,
          residentCity: res.data[0].personalInfo.residentCity,
          residentRegion: res.data[0].personalInfo.residentRegion
            ? res.data[0].personalInfo.residentRegion.regionName
            : "",
          residentRegion_si: res.data[0].personalInfo.residentRegion
            ? res.data[0].personalInfo.residentRegion.regionName_si
            : "",
          countryCode:
            res.data[0].personalInfo.residentCountry.code === "ZZ"
              ? res.data[0].personalInfo.residentCountryOther
                ? res.data[0].personalInfo.residentCountryOther.code
                : ""
              : res.data[0].personalInfo.residentCountry.code,

          country:
            res.data[0].personalInfo.residentCountry.code === "ZZ"
              ? res.data[0].personalInfo.residentCountryOther
                ? res.data[0].personalInfo.residentCountryOther.name
                : ""
              : res.data[0].personalInfo.residentCountry.name,

          country_si:
            res.data[0].personalInfo.residentCountry.code === "ZZ"
              ? res.data[0].personalInfo.residentCountryOther
                ? res.data[0].personalInfo.residentCountryOther.name
                : ""
              : res.data[0].personalInfo.residentCountry.name_si,

          age: res.data[0].personalInfo.age,

          profession:
            res.data[0].personalInfo.profession.name === "Other"
              ? res.data[0].personalInfo.professionOther
              : res.data[0].personalInfo.profession.name,
          profession_si:
            res.data[0].personalInfo.profession.name === "Other"
              ? res.data[0].personalInfo.professionOther
              : res.data[0].personalInfo.profession.name_si,

          ethnicity: res.data[0].personalInfo.ethnicity.name,
          ethnicity_si: res.data[0].personalInfo.ethnicity.name_si,

          religion: res.data[0].personalInfo.religion.name,
          religion_si: res.data[0].personalInfo.religion.name_si,

          caste: res.data[0].personalInfo.caste,
          height: res.data[0].personalInfo.height,

          status: res.data[0].personalInfo.civilStatus.status,
          status_si: res.data[0].personalInfo.civilStatus.status_si,

          originCountry: res.data[0].personalInfo.originCountry.name,
          originCountry_si: res.data[0].personalInfo.originCountry.name_si,

          visaType: res.data[0].personalInfo.visaType.visaType,
          visaType_si: res.data[0].personalInfo.visaType.visaType_si,

          eduLevel: res.data[0].personalInfo.educationLevel.level,
          eduLevel_si: res.data[0].personalInfo.educationLevel.level_si,

          drinking: res.data[0].personalInfo.drinking,
          smoking: res.data[0].personalInfo.smoking,

          foodPref: res.data[0].personalInfo.foodPreference.type,
          foodPref_si: res.data[0].personalInfo.foodPreference.type_si,

          description: res.data[0].personalInfo.additionalInfo,

          fCountryOfResidency: father[0].residentCountry
            ? father[0].residentCountry.name
            : "",
          fCountryOfResidency_si: father[0].residentCountry
            ? father[0].residentCountry.name_si
            : "",
          professionF: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name
            : "",
          professionF_si: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name_si
            : "",
          ethnicityF: father[0].ethnicity.name,
          ethnicityF_si: father[0].ethnicity.name_si,
          religionF: father[0].religion.name,
          religionF_si: father[0].religion.name_si,
          casteF: father[0].caste,
          additionalInfoF: father[0].additionalInfo,
          mCountryOfResidency: mother[0].residentCountry
            ? mother[0].residentCountry.name
            : "",
          mCountryOfResidency_si: mother[0].residentCountry
            ? mother[0].residentCountry.name_si
            : "",
          professionM: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name
            : "",
          professionM_si: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name_si
            : "",
          ethnicityM: mother[0].ethnicity.name,
          ethnicityM_si: mother[0].ethnicity.name_si,
          religionM: mother[0].religion.name,
          religionM_si: mother[0].religion.name_si,
          casteM: mother[0].caste,
          additionalInfoM: mother[0].additionalInfo,

          areThereMyPhotos: res.data[0].images.length > 0 ? true : false,
          images: res.data[0].images,
          phone: res.data[0].phone,
          email: res.data[0].email,
          horoscopeMatching: res.data[0].horoscopeMatching,
          horoscopeInfo: res.data[0].horoscopeInfo,
          dob: res.data[0].personalInfo.dob ? res.data[0].personalInfo.dob : "",

          siblingsInfo: res.data[0].siblingInfo ? res.data[0].siblingInfo : [],
          children:
            res.data[0].personalInfo &&
            res.data[0].personalInfo.civilStatus &&
            res.data[0].personalInfo.civilStatus.status !== "Never Married" &&
            res.data[0].personalInfo.children
              ? res.data[0].personalInfo.children
              : "",
        });

        this.getProfilePhotos(
          res.data[0].images.length > 0 ? res.data[0].images : []
        );

        this.getHoroImages(
          res.data[0].horoscopeInfo &&
            res.data[0].horoscopeInfo.images &&
            res.data[0].horoscopeInfo.images.length > 0
            ? res.data[0].horoscopeInfo.images
            : []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProfilePhotos = (profileImgs) => {
    if (profileImgs.length > 0) {
      for (let i = 0; i < profileImgs.length; i++) {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/v1/account/posts/${localStorage.getItem("postId")}/images/${
              profileImgs[i]
            }?thumbnail=false`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            this.setState({
              imageData: [...this.state.imageData, data],
              preLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.setState({
        preLoading: false,
      });
    }
  };

  getHoroImages = (horoImages) => {
    for (let i = 0; i < horoImages.length; i++) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem(
            "postId"
          )}/horoscope_images/${horoImages[i]}?thumbnail=false`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          let data = URL.createObjectURL(res.data);

          this.setState({
            imageHoroData: [...this.state.imageHoroData, data],
            preLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleChange = (e) => {
    this.setState({
      profileType: e.target.value,
      imageData: [],
      preLoading: true,
    });

    if (e.target.value === "full") {
      this.getPostInfo();
    } else {
      this.getNonInterestedData();
    }
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize title={`My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : (
      <React.Fragment>
        <PageHead title={`My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#dfdfdf", paddingTop: 60 }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            className="cus-inner-page cus-container-post"
          >
            <Button
              variant="contained"
              className="back-btn-in-detail-pages "
              onClick={() => this.props.history.goBack()}
            >
              <i className="fas fa-angle-left"></i> &nbsp;
              {this.props.lng ? "Back" : "ආපසු යන්න"}
            </Button>

            <select
              className="change-view-profile"
              name="profileType"
              value={this.state.profileType}
              onChange={this.handleChange}
              placeholder="-- Select --"
            >
              <option key="1" value="full">
                {this.props.lng
                  ? "View for matches"
                  : "ගැලපීම් වලට පෙනෙන අයුරු"}
              </option>
              <option key="2" value="ad">
                {this.props.lng ? "Public Ad" : "පොදු දැන්වීම"}
              </option>
            </select>

            <Link to="/edit-my-ad" className="edit-my-ad">
              Edit my ad
            </Link>

            {this.state.isRemovedAd && <RemovedAd lng={this.props.lng} />}

            {!this.state.isRemovedAd && (
              <Header
                lng={this.props.lng}
                preLoading={this.state.preLoading}
                fName={this.state.fName}
                lName={this.state.lname}
                lnameMasked={this.state.lnameMasked}
                createdBy={this.state.createdBy}
                gender={this.state.gender}
                age={this.state.age}
                height={this.state.height}
                residentCity={this.state.residentCity}
                profession={this.state.profession}
                profession_si={this.state.residentCity}
                countryCode={this.state.countryCode}
                country={this.state.country}
                country_si={this.state.country_si}
                interestInfo={this.state.interestInfo}
                description={this.state.description}
                images={this.state.images}
                imageData={this.state.imageData}
                areThereMyPhotos={this.state.areThereMyPhotos}
                applyNormalPost={this.state.applyNormalPost}
                applyMatch={this.state.applyMatch}
                isMyProfile={true}
                differentlyAbled={this.state.differentlyAbled}
                nicVerified={this.state.nicVerified}
                displayName={this.state.displayName}
                isFeatured={this.state.isFeatured}
                isMyAdFeatured={this.state.postData.isFeatured}
              />
            )}

            {!this.state.isRemovedAd && this.state.profileType === "full" && (
              <Connect
                lng={this.props.lng}
                preLoading={this.state.preLoading}
                phone={this.state.phone}
                email={this.state.email}
                profileType={this.state.profileType}
              />
            )}

            {!this.state.isRemovedAd && (
              <Personal
                lng={this.props.lng}
                preLoading={this.state.preLoading}
                ethnicity={this.state.ethnicity}
                ethnicity_si={this.state.ethnicity_si}
                religion={this.state.religion}
                religion_si={this.state.religion_si}
                age={this.state.age}
                status={this.state.status}
                status_si={this.state.status_si}
                country={this.state.country}
                country_si={this.state.country_si}
                originCountry={this.state.originCountry}
                originCountry_si={this.state.originCountry_si}
                visaType={this.state.visaType}
                visaType_si={this.state.visaType_si}
                residentRegion={this.state.residentRegion}
                residentRegion_si={this.state.residentRegion_si}
                residentCity={this.state.residentCity}
                caste={this.state.caste}
                height={this.state.height}
                eduLevel={this.state.eduLevel}
                eduLevel_si={this.state.eduLevel_si}
                profession={this.state.profession}
                profession_si={this.state.profession_si}
                drinking={this.state.drinking}
                smoking={this.state.smoking}
                foodPref={this.state.foodPref}
                foodPref_si={this.state.foodPref_si}
                children={this.state.children}
              />
            )}

            {!this.state.isRemovedAd && (
              <Parent
                lng={this.props.lng}
                preLoading={this.state.preLoading}
                fCountryOfResidency={this.state.fCountryOfResidency}
                fCountryOfResidency_si={this.state.fCountryOfResidency_si}
                professionF={this.state.professionF}
                professionF_si={this.state.professionF_si}
                ethnicityF={this.state.ethnicityF}
                ethnicityF_si={this.state.ethnicityF_si}
                religionF={this.state.religionF}
                religionF_si={this.state.religionF_si}
                casteF={this.state.casteF}
                additionalInfoF={this.state.additionalInfoF}
                mCountryOfResidency={this.state.mCountryOfResidency}
                mCountryOfResidency_si={this.state.mCountryOfResidency_si}
                professionM={this.state.professionM}
                professionM_si={this.state.professionM_si}
                ethnicityM={this.state.ethnicityM}
                ethnicityM_si={this.state.ethnicityM_si}
                religionM={this.state.religionM}
                religionM_si={this.state.religionM_si}
                casteM={this.state.casteM}
                additionalInfoM={this.state.additionalInfoM}
                siblings={this.state.siblingsInfo}
              />
            )}

            {!this.state.isRemovedAd && (
              <Horoscope
                lng={this.props.lng}
                preLoading={this.state.preLoading}
                horoscopeInfo={this.state.horoscopeInfo}
                horoscopeMatching={this.state.horoscopeMatching}
                dob={this.state.dob}
                imageHoroData={this.state.imageHoroData}
                originCountry={this.state.originCountry}
                originCountry_si={this.state.originCountry_si}
                isProfile={true}
                viewType={this.state.profileType}
              />
            )}
          </Grid>
        </div>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(MyAdd));
