import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeprecationHandler from "../../utils/deprecation-handler";

const withLanguageMigrator = (BaseComponent) => {
  return (props) => {
    const { i18n } = useTranslation();
    const [lng, setLng] = useState(
      DeprecationHandler.getLanguageFromI18n(i18n.language)
    );
    const { language } = i18n;

    useEffect(() => {
      setLng(DeprecationHandler.getLanguageFromI18n(language));
    }, [language]);

    return <BaseComponent {...props} lng={lng} />;
  };
};

export default withLanguageMigrator;
