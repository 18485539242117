// Package Imports
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";
import { isEmpty } from "lodash";

// Repository Implementations
import FormSection from "../../../../../components/molecules/form-section";
import Notice, { Varaint } from "../../../../../components/molecules/notice";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import FormDropdown from "../../../../../components/molecules/form-dropdown";
import FormDatePicker, {
  DATE_PATTERN,
} from "../../../../../components/molecules/form-date-picker";
import FormCheckbox from "../../../../../components/molecules/checkbox";
import FormTimePicker from "../../../../../components/molecules/form-time-picker";
import { useSelector } from "react-redux";
import AppContext from "../../../../../context";
import { HOROSCOPE_MATCHING_OPTIONS } from "../../../../../static-data";
import FormImageSelector from "../../../../../components/molecules/form-image-selector";
import "./style.css";
import ImageCrop from "../../../../../../components/image-crop/ImageCrop";
import {
  Base64ToBlob,
  FileToBase64,
  fieldVisibility,
  resizeImageToUpload,
} from "../../../../../utils";
import { getAuthTempProfileImage } from "../../../../../features/application";
import FormMeta from "../../schema/meta.json";
import Config from "../../../../../config";
import { ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS } from "../../../../../static-data";

// Domain Config
const NO_OF_HOROSCOPE_IMAGES = Config.POST_HOROSCOPE_IMAGE_SLOTS;

// Main Component
const HoroscopeInformation = () => {
  const { authTempProfile } = useSelector((state) => state.application);
  const { values, errors, handleChange, setFieldValue, submitCount } =
    useFormikContext();
  const { appLanguage: lang } = useContext(AppContext);
  const { t } = useTranslation();
  const { countries, starOptions, starSignOptions, haveDoshOptions } =
    useSelector((state) => state.genericData);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [horoscopeImages, setHoroscopeImages] = useState(
    Array.from(Array(NO_OF_HOROSCOPE_IMAGES).fill(undefined))
  );
  const [birthTime, setBirthTime] = useState(values.birthTime);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageMap, setImageMap] = useState({});

  const labelStyles = {
    ...(isMobileScreen ? {} : { width: "20%" }),
  };

  const checkboxStyles = {
    ...(isMobileScreen ? { width: "69%" } : { width: "89%" }),
  };

  const horoscopeInfoStyle = {
    ...(isMobileScreen ? { width: "30%" } : { width: "10%" }),
  };

  // event handlers
  const onSelectImage = async (file, index) => {
    try {
      const base64 = await FileToBase64(file);
      setSelectedImage({ slotId: index, source: base64 });
    } catch (e) {
      console.error(">>>> [Error!] onSelectImage ", e);
    }
  };

  const onDeleteImage = async (idx) => {
    try {
      const file = horoscopeImages[idx];
      if (typeof file === "string") {
        const mapEntry = Object.entries(imageMap).find(([_, url]) => {
          return url === file;
        });
        if (mapEntry !== undefined) {
          const deletedFile = mapEntry[0];
          const filteredImages = values.horoscopeImages.filter(
            (file) => file !== deletedFile
          );
          setFieldValue("horoscopeImages", filteredImages);
        }
      }
      let temp = [...horoscopeImages];
      temp[idx] = undefined;
      temp = temp.filter((image) => image !== undefined);
      const emptySlots = NO_OF_HOROSCOPE_IMAGES - temp.length;
      if (emptySlots > 0) {
        const slots = Array.from(Array(emptySlots).fill(undefined));
        temp = [...temp, ...slots];
        setHoroscopeImages(temp);
      }
    } catch (e) {
      console.log("Error! onDeleteImage ", e);
    }
  };

  const handleChangeValue = useCallback(
    (fieldName, value) => {
      setFieldValue(fieldName, value);
    },
    [setFieldValue]
  );

  const onCloseImageCrop = () => {
    setSelectedImage(undefined);
  };

  const handleChangeFile = async (e) => {
    try {
      const blob = await Base64ToBlob(e);
      resizeImageToUpload({
        blob,
        onResize: (ouptutBlob) => {
          if (selectedImage !== undefined) {
            let tempArray = values.horoscopeImages;
            let horoscopeImagesFieldArray = values.horoscopeImages;
            if (!Array.isArray(tempArray)) {
              return;
            }
            tempArray = [...horoscopeImages];
            const noOfEmptySlots =
              NO_OF_HOROSCOPE_IMAGES - horoscopeImagesFieldArray.length;
            if (noOfEmptySlots > 0) {
              const emptySlots = Array.from(
                Array(noOfEmptySlots).fill(undefined)
              );
              horoscopeImagesFieldArray.push(...emptySlots);
            }
            tempArray[selectedImage.slotId] = ouptutBlob;
            horoscopeImagesFieldArray[selectedImage.slotId] = ouptutBlob;
            setFieldValue("horoscopeImages", horoscopeImagesFieldArray);
            setHoroscopeImages(tempArray);
            setSelectedImage(undefined);
          }
        },
      });
    } catch (error) {
      console.error("Error! handleChangeFile ", error);
    }
  };

  const handleDownloadImages = useCallback(async () => {
    if (isEmpty(authTempProfile?.postData?.horoscopeImages)) {
      return;
    }
    let horoscopeImages = [];
    const imageMap = {};
    for (const image of authTempProfile.postData.horoscopeImages) {
      if (typeof image === "string") {
        const imageURL = await getAuthTempProfileImage({ id: image });
        imageMap[image] = imageURL;
        horoscopeImages.push(imageURL);
      }
    }

    const empyslots = NO_OF_HOROSCOPE_IMAGES - horoscopeImages.length;
    if (empyslots > 0) {
      const generatedSlots = Array.from(Array(empyslots).fill(undefined));
      horoscopeImages.push(...generatedSlots);
    }
    setImageMap(imageMap);
    setHoroscopeImages(horoscopeImages);
  }, [authTempProfile]);

  useEffect(() => {
    handleDownloadImages();
  }, [handleDownloadImages]);

  const dobStartDate = moment().subtract(60, "years").format(DATE_PATTERN);
  const dobEndDate = moment().subtract(18, "years").format(DATE_PATTERN);
  const showFieldErrors = submitCount > 0;

  useEffect(() => {
    handleChangeValue("birthTime", birthTime);
  }, [birthTime, handleChangeValue]);

  return (
    <FormSection heading={t("common.horoscopeDetails")}>
      <Grid container direction="row" style={{ rowGap: 16 }}>
        <Grid item xs={12} style={{ marginBlock: 8 }}>
          <Notice
            varaint={Varaint.primary}
            label={t("createflow.horoscopeDetailsNotice")}
            lang={lang}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          style={{ rowGap: 16 }}
        >
          <FormDatePicker
            value={values.birthdate}
            onChange={(value) =>
              setFieldValue(FormMeta.birthdate.fieldName, value)
            }
            disabled={true}
            startDate={dobStartDate}
            endDate={dobEndDate}
            label={t(FormMeta.birthdate.label)}
            labelStyles={labelStyles}
            lang={lang}
            errorText={showFieldErrors && errors.birthdate}
            visibility={fieldVisibility(FormMeta.birthdate)}
          />
          <FormDropdown
            fieldName={FormMeta.originCountryCode.fieldName}
            value={values.originCountryCode}
            label={t(FormMeta.originCountryCode.label)}
            onChange={handleChange}
            labelStyles={labelStyles}
            lang={lang}
            options={countries}
            keyExtractor={FormMeta.originCountryCode.keyExtractor}
            labelExtractor={FormMeta.originCountryCode.labelExtractor}
            errorText={showFieldErrors && errors.originCountryCode}
            visibility={fieldVisibility(FormMeta.originCountryCode)}
          />
          <FormDropdown
            fieldName={FormMeta.horoscopeMatching.fieldName}
            value={values.horoscopeMatching.toString()}
            onChange={(e) => {
              setFieldValue(
                FormMeta.horoscopeMatching.fieldName,
                JSON.parse(
                  e.target.value ??
                    FormMeta.horoscopeMatching.defaultValue.toString()
                )
              );
            }}
            label={t(FormMeta.horoscopeMatching.label)}
            labelStyles={labelStyles}
            lang={lang}
            options={HOROSCOPE_MATCHING_OPTIONS}
            errorText={showFieldErrors && errors.horoscopeMatching}
            visibility={fieldVisibility(FormMeta.horoscopeMatching)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="row"
          style={{ rowGap: 16 }}
        >
          <Grid xs={12} md={6} item>
            <FormTextInput
              fieldName={FormMeta.birthCity.fieldName}
              value={values.birthCity}
              onChange={handleChange}
              label={
                t(FormMeta.birthCity.label) + " (" + t("common.optional") + ")"
              }
              labelStyles={labelStyles}
              lang={lang}
              errorText={showFieldErrors && errors.birthCity}
              visibility={fieldVisibility(FormMeta.birthCity)}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <FormTimePicker
              value={values.birthTime}
              onChange={(value) => {
                setBirthTime(value);
              }}
              label={
                t(FormMeta.birthTime.label) + " (" + t("common.optional") + ")"
              }
              lang={lang}
              labelStyles={labelStyles}
              visibility={fieldVisibility(FormMeta.birthTime)}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ rowGap: 16 }}>
          <Grid xs={12} md={6}>
            <FormDropdown
              fieldName={FormMeta.starId.fieldName}
              value={values.starId}
              onChange={handleChange}
              options={starOptions}
              keyExtractor={FormMeta.starId.keyExtractor}
              labelExtractor={FormMeta.starId.labelExtractor}
              label={`${t(FormMeta.starId.label)} (${t("common.optional")})`}
              labelStyles={labelStyles}
              lang={lang}
              errorText={showFieldErrors && errors.starId}
              visibility={fieldVisibility(FormMeta.starId)}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <FormDropdown
              fieldName={FormMeta.starSignId.fieldName}
              value={values.starSignId}
              onChange={handleChange}
              options={starSignOptions}
              keyExtractor={FormMeta.starSignId.keyExtractor}
              labelExtractor={FormMeta.starSignId.labelExtractor}
              label={`${t(FormMeta.starSignId.label)} (${t(
                "common.optional"
              )})`}
              labelStyles={labelStyles}
              lang={lang}
              errorText={showFieldErrors && errors.starSignId}
              visibility={fieldVisibility(FormMeta.starSignId)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Grid style={checkboxStyles}>
            <FormCheckbox
              fieldName={FormMeta.showAdditionalInfo.fieldName}
              value={values.showAdditionalInfo}
              handleChange={handleChange}
              label={t(FormMeta.showAdditionalInfo.label)}
              lang={lang}
              visibility={fieldVisibility(FormMeta.showAdditionalInfo)}
            />
          </Grid>
        </Grid>
        {values.showAdditionalInfo && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            style={{ rowGap: 16 }}
          >
            <Grid item style={isMobileScreen ? {} : { width: "50%" }}>
              <FormDropdown
                fieldName={FormMeta.haveDosh.fieldName}
                value={values.hasDosh}
                onChange={handleChange}
                options={haveDoshOptions}
                keyExtractor={FormMeta.haveDosh.keyExtractor}
                labelExtractor={FormMeta.haveDosh.labelExtractor}
                label={t(FormMeta.haveDosh.label)}
                labelStyles={isMobileScreen ? {} : { width: "20%" }}
                lang={lang}
                errorText={showFieldErrors && errors.haveDosh}
                visibility={fieldVisibility(FormMeta.haveDosh)}
              />
            </Grid>
            <Grid item style={isMobileScreen ? {} : { width: "50%" }}>
              <FormDropdown
                fieldName={FormMeta.acceptOtherCaste.fieldName}
                value={values[FormMeta.acceptOtherCaste.fieldName]}
                onChange={handleChange}
                options={ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS}
                keyExtractor={FormMeta.acceptOtherCaste.keyExtractor}
                labelExtractor={FormMeta.acceptOtherCaste.labelExtractor}
                label={t(FormMeta.acceptOtherCaste.label)}
                labelStyles={isMobileScreen ? {} : { width: "38%" }}
                lang={lang}
                errorText={showFieldErrors && errors.acceptOtherCaste}
                visibility={fieldVisibility(FormMeta.acceptOtherCaste)}
              />
            </Grid>
            <FormTextInput
              fieldName={FormMeta.horoscopeDetail.fieldName}
              value={values.horoscopeDetail}
              onChange={handleChange}
              label={
                t(FormMeta.horoscopeDetail.label) +
                " (" +
                t("common.optional") +
                ")"
              }
              lang={lang}
              multiline={true}
              labelStyles={isMobileScreen ? {} : { width: "10%" }}
              placeholder={t(FormMeta.horoscopeDetail.placeholder)}
              errorText={showFieldErrors && errors.horoscopeDetail}
              visibility={fieldVisibility(FormMeta.horoscopeDetail)}
              maxCharactorCount={500}
            />
            <Grid
              item
              direction="row"
              container
              wrap="nowrap"
              style={{ rowGap: 8 }}
            >
              <Typography
                className={`horoscope-info-label horoscope-info-label-${lang}`}
                style={horoscopeInfoStyle}
              >
                {`${t("common.horoscopeDetails")} (${t("common.notRequired")})`}
              </Typography>
              <Grid
                xs
                container
                direction="row"
                style={{ columnGap: 8, rowGap: 8, paddingInline: 8 }}
              >
                {horoscopeImages.map((image, idx) => (
                  <Grid
                    key={`horoscope-img-${idx}`}
                    style={isMobileScreen ? { width: "48%" } : { width: "20%" }}
                  >
                    <FormImageSelector
                      image={image}
                      onSelect={(file) => onSelectImage(file, idx)}
                      onDelete={() => onDeleteImage(idx)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ImageCrop
        uploadImageModal={selectedImage !== undefined}
        selectedImage={selectedImage?.source}
        handleClose={onCloseImageCrop}
        handleChangeFile={handleChangeFile}
        photoLoading={false}
        handleSave={() => {}}
        lng={true}
      />
    </FormSection>
  );
};

export default HoroscopeInformation;
