import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react";

function MonthlyMagazines(props) {

  const [page, setPage] = useState(1);
  const [magazines, setMagazines] = useState([]);

  const itemsPerPage = 12;

  const data = [
    { "pdf": "https://static2.poruwa.lk/magazines/jan.pdf", "cover": "https://static.poruwa.lk/magazines/jan.jpg", "alt": "january", "en": "January 2021", "si": "ජනවාරි 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/feb.pdf", "cover": "https://static.poruwa.lk/magazines/feb.jpg", "alt": "february", "en": "February 2021", "si": "පෙබරවාරි 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/mar.pdf", "cover": "https://static.poruwa.lk/magazines/mar.jpg", "alt": "march", "en": "March 2021", "si": "මාර්තු 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/april.pdf", "cover": "https://static.poruwa.lk/magazines/april.jpg", "alt": "april", "en": "April 2021", "si": "අප්රේල් 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/may.pdf", "cover": "https://static.poruwa.lk/magazines/may.jpg", "alt": "may", "en": "May 2021", "si": "මැයි 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/june.pdf", "cover": "https://static.poruwa.lk/magazines/june.jpg", "alt": "june", "en": "June 2021", "si": "ජූනි 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/july.pdf", "cover": "https://static.poruwa.lk/magazines/july.jpg", "alt": "july", "en": "July 2021", "si": "ජුලි 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/august.pdf", "cover": "https://static.poruwa.lk/magazines/august.jpg", "alt": "august", "en": "August 2021", "si": "අගෝස්තු 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/sep.pdf", "cover": "https://static.poruwa.lk/magazines/sep.jpg", "alt": "september", "en": "September 2021", "si": "සැප්තැම්බර් 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/october.pdf", "cover": "https://static.poruwa.lk/magazines/october.png", "alt": "october", "en": "October 2021", "si": "ඔක්තෝම්බර් 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/november.pdf", "cover": "https://static.poruwa.lk/magazines/november.png", "alt": "november", "en": "November 2021", "si": "නොවැම්බර් 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/december.pdf", "cover": "https://static.poruwa.lk/magazines/december.jpg", "alt": "december", "en": "December 2021", "si": "දෙසැම්බර් 2021" },
    { "pdf": "https://static2.poruwa.lk/magazines/jan-2022.pdf", "cover": "https://static.poruwa.lk/magazines/jan-2022.png", "alt": "january", "en": "January 2022", "si": "ජනවාරි 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/feb-2022.pdf", "cover": "https://static.poruwa.lk/magazines/feb-2022.jpg", "alt": "february", "en": "February 2022", "si": "පෙබරවාරි 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/march-2022.pdf", "cover": "https://static.poruwa.lk/magazines/march-2022.jpg", "alt": "march", "en": "March 2022", "si": "මාර්තු 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/april-2022.pdf", "cover": "https://static.poruwa.lk/magazines/april-2022.jpg", "alt": "april", "en": "April 2022", "si": "අප්රේල් 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/may-2022.pdf", "cover": "https://static.poruwa.lk/magazines/may-2022.jpg", "alt": "may", "en": "May 2022", "si": "මැයි 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/jun-2022.pdf", "cover": "https://static.poruwa.lk/magazines/cover-jun-2022.jpg", "alt": "june", "en": "June 2022", "si": "ජූනි 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/july-2022.pdf", "cover": "https://static.poruwa.lk/magazines/july-2022.jpg", "alt": "july", "en": "July 2022", "si": "ජුලි 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/august-2022.pdf", "cover": "https://static.poruwa.lk/magazines/August-2022.jpg", "alt": "august", "en": "August 2022", "si": "අගෝස්තු 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/september-2022.pdf", "cover": "https://static.poruwa.lk/magazines/september-2022.jpg", "alt": "september", "en": "September 2022", "si": "සැප්තැම්බර් 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/october-2022.pdf", "cover": "https://static.poruwa.lk/magazines/october-2022.jpg", "alt": "october", "en": "October 2022", "si": "ඔක්තෝම්බර් 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/nov-2022.pdf", "cover": "https://static.poruwa.lk/magazines/nov-2022.jpg", "alt": "november", "en": "November 2022", "si": "නොවැම්බර් 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/dec-2022.pdf", "cover": "https://static.poruwa.lk/magazines/dec-2022.jpg", "alt": "december", "en": "December 2022", "si": "දෙසැම්බර් 2022" },
    { "pdf": "https://static2.poruwa.lk/magazines/jan-2023.pdf", "cover": "https://static.poruwa.lk/magazines/jan-2023.jpg", "alt": "january", "en": "January 2023", "si": "ජනවාරි 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/feb-2023.pdf", "cover": "https://static.poruwa.lk/magazines/feb-2023.jpg", "alt": "february", "en": "February 2023", "si": "පෙබරවාරි 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/march-2023.pdf", "cover": "https://static.poruwa.lk/magazines/march-2023.jpg", "alt": "march", "en": "March 2023", "si": "මාර්තු 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/april-2023.pdf", "cover": "https://static.poruwa.lk/magazines/april-2023.jpg", "alt": "april", "en": "April 2023", "si": "අප්රේල් 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/may-2023.pdf", "cover": "https://static.poruwa.lk/magazines/may-2023.jpg", "alt": "may", "en": "May 2023", "si": "මැයි 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/jun-2023.pdf", "cover": "https://static.poruwa.lk/magazines/jun-2023.jpg", "alt": "june", "en": "June 2023", "si": "ජූනි 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/july-2023.pdf", "cover": "https://static.poruwa.lk/magazines/july-2023.jpg", "alt": "july", "en": "July 2023", "si": "ජුලි 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/aug-2023.pdf", "cover": "https://static.poruwa.lk/magazines/aug-2023.jpg", "alt": "august", "en": "August 2023", "si": "අගෝස්තු 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/sep-2023.pdf", "cover": "https://static.poruwa.lk/magazines/sep-2023.jpg", "alt": "september", "en": "September 2023", "si": "සැප්තැම්බර් 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/oct-2023.pdf", "cover": "https://static.poruwa.lk/magazines/oct-2023.jpg", "alt": "october", "en": "October 2023", "si": "ඔක්තෝම්බර් 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/nov-2023.pdf", "cover": "https://static.poruwa.lk/magazines/nov-2023.jpg", "alt": "november", "en": "November 2023", "si": "නොවැම්බර් 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/dec-2023.pdf", "cover": "https://static.poruwa.lk/magazines/dec-2023.jpg", "alt": "december", "en": "December 2023", "si": "දෙසැම්බර් 2023" },
    { "pdf": "https://static2.poruwa.lk/magazines/jan-2024.pdf", "cover": "https://static.poruwa.lk/magazines/jan-2024.jpg", "alt": "december", "en": "January 2024", "si": "ජනවාරි 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/feb-2024.pdf", "cover": "https://static.poruwa.lk/magazines/feb-2024.jpg", "alt": "february", "en": "February 2024", "si": "පෙබරවාරි 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/march-2024.pdf", "cover": "https://static.poruwa.lk/magazines/march-2024.jpg", "alt": "march", "en": "March 2024", "si": "මාර්තු 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/apr-2024.pdf", "cover": "https://static.poruwa.lk/magazines/apr-2024.jpg", "alt": "april", "en": "April 2024", "si": "අප්රේල් 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/may-2024.pdf", "cover": "https://static.poruwa.lk/magazines/may-2024.jpg", "alt": "may", "en": "May 2024", "si": "මැයි 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/jun-2024.pdf", "cover": "https://static.poruwa.lk/magazines/jun-2024.jpg", "alt": "june", "en": "June 2024", "si": "ජූනි 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/jul-2024.pdf", "cover": "https://static.poruwa.lk/magazines/jul-2024.jpg", "alt": "july", "en": "July 2024", "si": "ජුලි 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/aug-2024.pdf", "cover": "https://static.poruwa.lk/magazines/aug-2024.jpg", "alt": "august", "en": "August 2024", "si": "අගෝස්තු 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/sep-2024.pdf", "cover": "https://static.poruwa.lk/magazines/sep-2024.jpg", "alt": "september", "en": "September 2024", "si": "සැප්තැම්බර් 2024" },
    { "pdf": "https://static2.poruwa.lk/magazines/oct-2024.pdf", "cover": "https://static.poruwa.lk/magazines/oct-2024.jpg", "alt": "october", "en": "October 2024", "si": "ඔක්තෝම්බර් 2024" }
  ]

const calPagesCount = () => {
  return Math.ceil(data.length/itemsPerPage);
};

useEffect(()=>{
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  setMagazines(data.reverse().slice(startIndex,endIndex));
},[page])

return (
  !isEmpty(data) && !isEmpty(magazines) && (
    <>
      <p className="maga-sec-heading">
        {props.lng ? "Monthly Magazines" : "මාසික සඟරා"}
      </p>
      <Grid container direction="row">
        {magazines.map((magazine) => (
          <a href={magazine.pdf} target="_blank" className="maganize-card">
            <img src={magazine.cover} alt={magazine.alt} className="maganize-img" />
            <span>{props.lng ? magazine.en : magazine.si}</span>
          </a>
        ))}
      </Grid>
      {data.length > itemsPerPage && (
        <Grid container direction="row" justifyContent="center">
          <Pagination
            count={calPagesCount()}
            page={page}
            onChange={(e, value) => {
              setPage(value);
              document.body.scrollTop = 0;
            }}
          />
        </Grid>
      )}
    </>
  )
);
}

export default MonthlyMagazines;
